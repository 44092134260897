
window.preloadImg = function(imgs){
    for (let i = 0; i < imgs.length; i++){
        let img = $('body').createElementInside('img').addClass('image-preload').addAttr('src', imgs[i]);
    }
}

window.carouselWithContent = function(target, duration = 10000){
    var tabImg = [];
    var nav = [];
    var timeOut = null;
    target = $(target);
    const parent = target.parentElement;
    var index = 0;
    var current = null;
    var currentNav = null;
    var navigation = target.createElementInside('ul');
    navigation.addClass('caroussel-navigation');
    var imgs = target.find('.caroussel-item');

    const choise = function(e){
        clearTimeout(timeOut);
        e.stopPropagation();
        e.preventDefault();
        index = this.dataset.index - 1;
        swapImg();
    }

    for (let i = 0; i < imgs.length; i++){
        tabImg.push(imgs[i]);
        var li = navigation.createElementInside('li');
        li.addAttr('data-index', i);
        if(i == 0) li.addClass('show');
        li.addEventListener('click', choise);
        nav.push({'btn' : li, 'index' : i, 'target' : imgs[i]});
    }

    const reset = function(){
        for (let i = 0; i < imgs.length; i++) imgs[i].removeClass('show');
        for (let i = 0; i < nav.length; i++) nav[i].btn.removeClass('show');
    }

    const swapImg = function(){
        reset();
        if(current) current.removeClass('show');
        index ++;
        if(!tabImg[index]) index = 0;
        current = tabImg[index].addClass('show');
        currentNav = nav[index];
        currentNav.btn.addClass('show');
        timeOut = setTimeout(swapImg, duration);
    }
    timeOut = setTimeout(swapImg, duration);
}

window.carousel = function(imgs, target, duration = 10000){
    var tabImg = [];
    target = $(target);
    const parent = target.parentElement;
    var index = 0;
    var current = null;

    for (let i = 0; i < imgs.length; i++){
        let _img = new Image;
        _img.src = imgs[i].img;
        _img.addClass('carousel-img');
        parent.insertBefore(_img, target.nextSibling);
        tabImg.push(_img);
    }

    const legend = function(){
        parent.removeAttr('data-content').removeClass('legend-on');
        let legend = imgs[index].legend;
        if(imgs[index].credit != '') legend += legend != '' ? ' - ' + imgs[index].credit : imgs[index].credit;
        if(legend != '') parent.addAttr('data-content', legend).addClass('legend-on');
    }

    const swapImg = function(){
        if(current) current.removeClass('show');
        index ++;
        if(!tabImg[index]) index = 0;
        current = tabImg[index].addClass('show');
        legend();
    }

    const trySwapImg = function(){
        if(tabImg[index].complete){
            swapImg();
            setTimeout(trySwapImg, duration);
        }else{
            setTimeout(trySwapImg, 100);
        }
    }
    setTimeout(trySwapImg, duration);
}
